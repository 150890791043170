/* eslint-disable no-underscore-dangle */
import BaseView from '../../../js/base-view';

const IS_READY_CLASS = 'is-ready';
// const SELECTOR_SUBELEMENT = '[data-subelement]';
// const ATTR_MYPROPERTY = 'data-myproperty';

export default class ApWatch extends BaseView {
	/**
	 * Entrypoint
	 */
	init() {
		// set vendor global functions
		// assetLoaded_COMPLETE() est appelé quand les assets graphique de la montre sont chargés;
		window.assetLoaded_COMPLETE = function() {
			// console.log('assetLoaded_COMPLETE');
			window._mtd_gotoCurrentTime();
		};

		// gotoCurrentTime_COMPLETE() est appelé à la fin de l'animation de la
		// fonction _mtd_gotoCurrentTime() ou _mtd_replayGotoCurrentTime();
		// TODO: these vars could be local (state)
		window.timerDemo = undefined;
		window.timer_stopChrono = undefined; // useless?
		window.timer_resetChrono = undefined; // useless?

		window.timerDemo_COMPLETE = function() {
			// console.log('timerDemo_COMPLETE');
			window._mtd_clic2h();
			clearInterval(window.timerDemo);
		};

		window.gotoCurrentTime_COMPLETE = function() {
			// console.log('gotoCurrentTime_COMPLETE');
			window._mtd_clic4h();
			window.timerDemo = setInterval(window.timerDemo_COMPLETE, 1000);
		};

		// init watch
		this.initUI();

		// attach events
		this.bindEvents();

		// state
		this.state = {
			vw: this.viewport.getViewportWidth(),
		};
	}

	/**
	 * Bind component's events
	 */
	bindEvents() {
		this.on('resizeSlow', this.onResize.bind(this));
	}

	// --------------------------------
	// #region Event Handlers
	// --------------------------------

	/**
	 * When the viewport size changes
	 */
	onResize() {
		// resize watch only if width changed
		// (we don't care about height changes as it would trigger too often on mobile)
		const lastVw = this.state.vw + 0;
		this.state.vw = this.viewport.getViewportWidth();
		if (this.state.vw === lastVw) {
			return;
		}

		// reinit the component
		this.destroyUI();
		this.initUI();
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Actions
	// --------------------------------

	/**
	 * Init UI
	 */
	initUI() {
		// flag to know if the watch was once loaded already
		// window.apWatchWasOnceLoaded = true;

		// set vendor global vars
		window._mtd_hauterMontre = this.getHeight() * 1.28; // en pixels largeurMAX = 600;
		window._mtd_dureeAnimation = 0; // en seconde
		window.dureeAnimation = 3; // en seconde
		// init watch
		if (typeof window.initAPwatch === 'function') {
			this.initWidget();
		}
		else {
			this.loadScripts().then(() => {
				this.initWidget();
			});
		}
	}

	/**
	 * Load script
	 *
	 * @return {Promise} a promise, resolved when all scripts loaded, rejectes when 1 or more resources failed to load
	 */
	loadScripts() {
		const scripts = this.el.getAttribute('data-ap-watch-scripts').split(',');
		const promises = scripts.map((scriptUrl) => new Promise((resolve, reject) => {
			// vars
			const existingScript = document.querySelector(`script[src="${ scriptUrl }"]`);
			// reject if script already present
			if (existingScript) {
				reject();
			}
			// inject script and resolve when loaded
			const el = document.createElement('script');
			el.onload = resolve;
			el.onerror = reject;
			el.src = scriptUrl;
			document.getElementsByTagName('head')[ 0 ].appendChild(el);
		}));
		return Promise.all(promises);
	}

	/**
	 * init Widget
	 */
	initWidget() {
		window.initWatch_AP();
		this.el.classList.add(IS_READY_CLASS);
	}

	/**
	 * Destroy UI
	 */
	destroyUI() {
		clearInterval(window.timerDemo);
		if (typeof window.destroyAPwatch === 'function') {
			window.destroyAPwatch();
			this.el.classList.remove(IS_READY_CLASS);
		}
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Helpers
	// --------------------------------

	getHeight() {
		return this.el.getBoundingClientRect().height;
	}

	// --------------------------------
	// #endregion
	// --------------------------------

	/**
	 * Before the component gets destroyed
	 * - unbind any event not bound with this.on()
	 * - reset UI if needed (any classes/attributes added?)
	 */
	beforeDestroy() {
		// destroy watch
		this.destroyUI();
	}
}
